<template>
  <nav
    class="navbar navbar-expand-lg inner-header d-flex justify-content-between px-3 py-2 "
  >
    <a class="navbar-brand brand-logo" href="#">
      <img :src="logo" alt="">
    </a>

    <div class="" id="navbarSupportedContent">
      <ul class="d-flex gap-3 align-items-center mb-0">
        <label class="switch" v-if="darkModePresent">
            <input type="checkbox" @change="toggleTheme()" :checked="isDarkMode">
            <span class="sliderToggle"></span>
        </label>
        <router-link to="/" class="text-center">
         <img src="../../assets/images/homeicon.svg" class="homeicons"  />
          
          <p class="headerpara">Home</p>
              </router-link>
              <router-link to="/dashboard" class="text-center">
                <img src="/images/dashboard.png" alt="" width="20px">
                 <p class="headerpara">Dashboard</p>
              </router-link>
              <ProfileDropdown @logOutUser="logout"/>
      </ul>
    </div>
    <div class="modal" id="tokenWarningModal" tabindex="-1" role="dialog" aria-labelledby="tokenWarningModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Session Expiry Warning</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <a>
                    <span class="p-2 modalspan"  > Your session is about expire. Please click Ok to continue</span>
                </a>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="next-btn mb-4" @click="tokenExpireWarningCancel = true"  >Cancel</button>
                <button type="button" class="next-btn mb-4" @click="tokenExpireWarning = true"  >Ok</button>
            </div>
            </div>
        </div>
        </div>
  </nav>
</template>

<script>
import { getDistributer, hasDarkMode } from '@/mixins/common';
import { Buffer } from "buffer";
// import axios from "axios";
import jwt_decode from "jwt-decode";
import $ from "jquery";
import ProfileDropdown from './profileDropdown.vue';
export default {
  name: "innerHeader",
  components: {
    ProfileDropdown

  },
  async mounted() {
    this.theme = this.$store.state.theme;
    this.logo= this.$store.state.theme =='light' ? getDistributer()["distributorLogo"] : getDistributer()["distributorLogoDark"];
    let diy = this.$route.query.diy ? this.$route.query.diy : false;
    if (sessionStorage.getItem("diy") == null) {
      sessionStorage.setItem("diy", diy);
    }
    if (this.$store.state.token && this.$route.query.id) {
      let rm_id = this.$route.query;
      if (rm_id) {
        this.startSession(rm_id);
      }
    } else if (this.$store.state.token && sessionStorage.getItem("rm_data")) {
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
      if (JSON.parse(sessionStorage.getItem("sessionExpiryTime")) == true) {
        this.tokenWarning();
      }
      if(this.rmId && (this.rmId!=this.rmData["transaction_id"])){
        sessionStorage.clear();
        this.startSession(this.rmId)
      }
    } else if(this.$route.query.token){
      try {
        const token = this.$route.query.token;
        this.decodeToken = jwt_decode(this.$route.query.token);
        const journey_id = this.decodeToken.PAYLOAD.journey_id;
        const transId = this.decodeToken.PAYLOAD.transactionId;
        if(this.decodeToken.PAYLOAD.externalReferenceId == "" && this.decodeToken.PAYLOAD.userid == ""){
          let msg=['Token is Invalid']
          this.$router.push({ path: '/errorhandle', query: {backUrl:'savingsGateCriteria', msg: msg}});
        }
        sessionStorage.setItem("journey_id", journey_id);
        this.$store.commit("setJourneyId", journey_id);
        this.$store.commit("setToken", this.$route.query.token);
        this.fetchRmDetails(token);
      }
      catch (err) {
        console.log('token is null: ', err);
      }
    } else if (!this.$store.state.token) {
      let rm_id = this.$route.query;
      if (rm_id) {
        this.startSession(rm_id);
      }
    }
    if (!this.$store.state.configData && this.$store.state.token) {
        this.getConfigData();
      }
    this.distributorLogo = getDistributer()["distributorLogo"];
    const isPreFilled = getDistributer()['isPreFilled'];
    if (isPreFilled && this.$store.state.token) {
      if (!this.$store.state.customerData) {
        await this.getCustomerDetails();
      }
    }
    this.darkModePresent = hasDarkMode();
  },
  unmounted() {
    clearInterval(this.intervalTime); // - Clears Interval
  },
  data() {
    return {
      darkModePresent: false,
      decodeToken:"",
      multiQuote:false,
      rmData: "",
      loading: false,
      tokenExpireWarning: false,
      tokenExpireWarningCancel: false,
      intervalTime: null,
      logo:'',
      deepLinkParams:{
        productId: undefined,
        manufacturerId : undefined,
        quoteAmount: undefined,
        premiumPaymentTerm: undefined,
        premiumPaymentFrequency: undefined,
        policyTerm: undefined
      }
    };
  },
  computed: {
    theme() {
      return this.$store.state.theme;
    },
    isDarkMode() {
      return this.theme === 'light';
    }
  },
  methods:{
    toggleTheme(){
      this.$store.dispatch('toggleTheme');
    },
    startSession(rm_id){
      let data = {};
      if(rm_id?.journeyId != null){
        this.rmId = rm_id;
        sessionStorage.clear();
        sessionStorage.setItem("journeyId",this.rmId.journeyId);
      this.$store.commit("setJourneyId", this.rmId.journeyId);
        data = {
          journey_id: this.rmId.journeyId
        }
      } else{
        this.rmId = rm_id;
        data = {
          transactionId: this.rmId.id
        }
      }    
      const url = this.$route.query.override ? 'generateTokenOverride' : 'generateToken';
      this.backendApi(url, data)
      .then((resp) => {
        console.log(resp.data)
        if(resp.data.errors.length>0){
          this.$router.push({ path: '/error-page', query: {msg: resp.data.errors[0]['errorMessage'] }});
        }
        let token = resp.data.data.token.access_token;
        if (getDistributer()["isPreFilled"]) {
          this.decodeToken = jwt_decode(token);
          this.$store.commit("setCustomerId", this.decodeToken.PAYLOAD.externalReferenceId);
        }
        console.log(token,"fetchRmDetails token")
        this.fetchRmDetails(token)
      })
      .catch((error) => {
        console.log("Failed to start session",error);
        this.$router.push({ path: '/error-page', query: {msg: error }});

      })
    },
    fetchRmDetails(token){
      this.backendApi("getRmDetails", [], {"Authorization":`Bearer ${token}`}, "get")
        .then((response) => {
          //console.log('save quote response: ', response);
          if(response.data.errors.length>0 == 'error') {
            this.$router.push({ path: '/error-page', query: {msg: response.data.errors[0]['errorMessage'] }});
          } else if(response && response.data) {
            this.rmData = response.data.data;
            console.log(response);
            console.log('rmData: 4: ', this.rmData);
            if(this.rmData != undefined)
            {
              sessionStorage.setItem("rm_data", JSON.stringify(this.rmData));
              sessionStorage.setItem("sessionExpiryTime",'true');
              if(this.rmData["RoleId"]=="1"){
                sessionStorage.setItem('diy','true');
              }
            }
          }
          if(!this.rmData){
            this.$store.commit('setToken',null)
          } else {
            this.$store.commit('setToken',token)
            this.$store.commit('setRmId','')
            this.$emit('clicked-rm-details', this.rmData);
            // window.history.replaceState("", "", new URL(location).origin);
            // window.location.reload();
            // let url = new URL(location).origin;

                       {  
                if(getDistributer()["distributerID"] == "KVB"){
                const newLocation = location.origin + "/#/welcome";
                location.href = newLocation;
                }
                else{
                  window.location.href = new URL(location).origin;
                }
            }
          }
        })
        .catch((error) => {
          console.log('error msg block dp', error);
          this.$router.push({ path: '/error-page', query: {msg: error }});
        });
    },
    logout(isLogout = true) {
      if (!isLogout) {
        this.handleHomepage();
      } else {
        const logOutUrl = this.rmData && this.rmData.LogOutUrl;
        sessionStorage.clear();
        localStorage.clear();
        if (sessionStorage.getItem("rm_data")) {
          this.rmData = JSON.parse(sessionStorage.getItem("rm_data"))
        }
        else{
          this.rmData = "";
        }
        if (logOutUrl && logOutUrl !== null) {
          window.location.href = logOutUrl;
        } else {
          if (this.rmData['RoleId'] == '1') {
            window.location.href = getDistributer()['loginUrlB2C'];
          }
          else {
            window.location.href = "/"
          }
        }
      }
    },
    getConfigData() {

      var headers = {
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_USERNPASS),
        "Content-Type": "application/json",
      };

      let reqData = [getDistributer()["distributerID"]];

      this.backendApi("getConfigData", reqData, headers, "get")
        .then((response) => {
          if (response == "error") {
            console.log("get config data error: block");
          } else if (response && response.data) {
            console.log("get config data success: response ", response.data.data.Jsondata[0].jsonDataUI);
            this.$store.commit('setConfigData', response.data.data.Jsondata[0].jsonDataUI)
            console.log("hdjfh", this.$store.state.configData)
          }
        })
        .catch((error) => {
          console.log("error msg block get config data", error);
        });

    },
    handleHomepage() {
      const consumer = getDistributer();
      if (consumer["consumerId"] === "KVB") {
        window.location.href = consumer.baseURL;
      } else {
        window.location.href = window.location.origin + "/#/";
      }
    },
    tokenWarning() {
    let self = this;
    this.intervalTime = setInterval( function(){
      let decodedToken = self.parseJwt(self.$store.state.token)
      // console.log("kjsdbnbv",((decodedToken['expires'] - Date.now() / 1000) / 60));
      let timer = ((decodedToken['expires'] - Date.now() / 1000) / 60);
      if (timer <= 1 && timer > 0) {
        // token getting expired (have to refresh)
        $("#tokenWarningModal").modal("show");
        if(self.tokenExpireWarningCancel == true){
          sessionStorage.setItem("sessionExpiryTime",'false');
          clearInterval(self.intervalTime);
          $("#tokenWarningModal").modal("hide");
        }
        if(self.tokenExpireWarning == true){
          $("#tokenWarningModal").modal("hide");
          let data = {
            currentToken: self.$store.state.token
          }
          self.backendApi("refreshToken", data)
          .then((resp) => {
            if(resp.data.errors.length>0){
              self.$router.push({ path: '/error-page', query: {msg:"Unable to refresh token"}});
            }
            self.tokenExpireWarning = false;
            let token = resp.data.data.newToken.access_token;
            self.$store.commit('setToken',token);
            self.callTokenWarning();
          })
          .catch((error) => {
            console.log("Failed to start session",error);
            let msg= "Failed to start session"
            self.$router.push({ path: '/error-page', query: {msg: msg }});
          })
        }
      }
      else if(timer <= 0){
        sessionStorage.setItem("sessionExpiryTime",'false');
        clearInterval(self.intervalTime);
         $("#tokenWarningModal").modal("hide");
        sessionStorage.removeItem('vuex')
        window.location.href = `${window.location.origin}/#/networkalert?msg=Your session time is expired , kindly login again&backUrl=logout`
        // window.history.replaceState("", "", `${window.location.origin}/#/networkalert?msg=Your session time is expired , kindly login again&backUrl=logout`);
        // window.location.reload();
      }
    },1000);
  },
  callTokenWarning(){
    clearInterval(this.intervalTime);
    this.tokenWarning();
  },
  parseJwt(token) {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
   },
   async getCustomerDetails() {
      const customerId = this.$store.state.customerId || '123456';
      const body = {
        "customerHashId": customerId,
        "pan": "",
        "firstName": "",
        "lastName": "",
        "dob": ""
      };
      const response = await this.backendApi('getCustomerDetails', body, {});
      const customerData = response.data.data.customer_info;
      this.$store.commit("setCustomerData", customerData);
    },
  },
  watch: {
    theme(newTheme) {
      if (newTheme) {
        this.logo= newTheme =='dark' ? getDistributer()["distributorLogoDark"] : getDistributer()["distributorLogo"];
        document.body.className = newTheme;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/components/inner-header.scss";
</style>
