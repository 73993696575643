/* eslint-disable */
import { createStore, Store } from 'vuex'
import createPersistedState from 'vuex-persistedstate';
export default createStore({
  state: {
    isAuthenticated:false,
    token:'',
    configData: '',
    journeyId: "",
    motorProductType:"",
    rmId: `00000000-0000-0000-0000-000000000000`,
    quoteCategory:'',
    customerData: "",
    customerId: '',
    theme: 'light'
  },
  mutations: {
    loginUser(state) {
      state.isAuthenticated = true;
    },
    setToken(state,token) {
      state.token = token;
    },
    setConfigData(state,data) {
      state.configData = data;
    },
    setJourneyId(state, journeyId){
      state.journeyId = journeyId;
    },
    setRmId(state){
      state.rmId = '';
    },
    setMotorProductType(state,motorProductType){
      state.motorProductType = motorProductType;
    },
    setquoteCategory(state,quoteCategory){
      state.quoteCategory = quoteCategory;
    },
    setCustomerData(state, customerData){
      state.customerData = customerData;
    },
    setCustomerId(state, customerId){
      state.customerId = customerId;
    },
    setTheme(state, mode){
      state.theme = mode
    }
  },
  actions: {
    // loginUser({ commit }, body) {
    //   commit("loginUser",body);
    // }, 
    toggleTheme({ commit, state }) {
      const newTheme = state.theme === 'light' ? 'dark' : 'light';
      commit('setTheme', newTheme);
    }
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
  getters: {
    // isAuthenticatedUser(state,getters){
    //   return state.isAuthenticated;
    // },
    getToken(state){
      return state.token;
    },
    getConfigData(state){
      return state.configData;
    },
  },
  modules: {},
});