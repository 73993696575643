<template>
  <InnerHeader />

  <div class="row justify-content-center">
    <div class="col-12 col-md-10 col-lg-8 col-xl-8 col-xxl-8">
      <div class="single-quote formmargin p-3">
        <div class="row">
          <div class="col-md-6 formobileview position-sticky">
            <div class="formWizard  " style="top: 5rem">
              <h3 class="processheading">
                Confirm & Proceed
              </h3>
              <div class="formobileview">
                <div class="single-quote headingcards d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="mb-0">
                      {{ motorProductType === '4W-Pvt' ? 'Car Details' : 'Bike Details' }}
                    </h6>
                    <p class="mb-0">
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.rtoCode }}&nbsp;&nbsp;</span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.carMake?.makeDescription }}&nbsp;&nbsp;</span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.model?.modelDesc }}&nbsp;&nbsp; </span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.fuelType?.fuelType.toUpperCase()}}&nbsp;&nbsp;</span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        this.userData.variant?.variantDesc }}&nbsp;&nbsp; </span>
                      <span><img src="../../assets/images/dot.svg" class="doticon" width="3px" />{{
                        formatDate(this.userData.RegDate)}} </span>
                    </p>
                  </div>

                </div>

                <!-- <ul class="nav nav-tabs d-block m-3 justify-content-left border-0" id="myTab" role="tablist">
                  <li v-for="(step, index) in steps" :key="index" class="nav-item completed" role="presentation"
                    :data-bs-toggle="tooltip" data-bs-placement="top" :title="step.title">
                    <a :class="[
                    'd-flex',
                    'align-items-center',
                    'justify-content-between',
                    'gap-2',
                    { active: activeTab === index },
                  ]" @click="selectTab(index)" role="tab" :aria-controls="'step' + (index + 1)"
                      :aria-selected="activeTab === index" style="width: 100%">
                      <div class="d-flex justify-content-between" style="width: 100%">
                        <div class="d-flex align-items-center gap-1">
                          <i class="activeicon">{{ step.icon }}</i>
                          <div class="checkincon">
                            <img src="../../assets/images/check.svg" />
                          </div>
                          <p class="mb-0" v-html="step.subcontent"></p>
                        </div>
                         <a href="" class="editform" data-toggle="modal" data-target="#savingseditprofile">
                         
                          edit
                        </a>
                      </div>
                    </a>
                  </li>
                </ul> -->
              </div>
            </div>
            <div class="mt-3">
              <article class="FilterAccordian headingcards" v-for="item in items" :key="item.accoheading"
                v-show="responseData?.length > 0">
                <header class="d-flex justify-content-between align-items-center">
                  <h6 @click="item.expanded = !item.expanded" class="question-title mb-0">
                    Add Ons
                  </h6>
                  <button class="btn p-0" @click="item.expanded = !item.expanded">
                    <img src="../../assets/images/plus.svg" class="plusminusicon" width="10px" alt=""
                      v-show="item.expanded">

                    <img src="../../assets/images/minus.svg" class="plusminusicon" width="10px" alt=""
                      v-show="!item.expanded">

                  </button>
                </header>
                <div>
                  <div v-if="!item.expanded">

                    <h4 class="mb-2"></h4>

                    <div class=" mb-2" v-for="(item,index) in this.filteredOptions " :key="item">
                      <div class=" addonplan align-items-center"
                        :class="{'lastChildList': index == this.filteredOptions.length-1}">
                        <div class="d-flex align-items-center justify-content-between" :class="{'mb-2': item.optionValue.length>0}">
                          <div class="d-flex gap-2 align-items-center">
                            <img src="../../assets/images/checked.png" alt="" width="14" height="14"
                              v-if="item.checkBoxStatus" :class="{'disableCheck': item.checkBoxStatus}"
                              draggable="false" />
                            <input v-if="!item.checkBoxStatus" class="form-check-input" type="checkbox"
                              id="flexCheckDefault3" v-model="item.isChecked" @click="recalculate=true"
                              @change="clearSelectedValues(item, $event.target.checked, item.optionSelected)"
                              :disabled="item.checkBoxStatus">
                            <h5>{{ item["optionDescription"] }}</h5>
                          </div>
                          <div v-if="item.optionValue.length > 1 && item.isChecked">
                          </div>
                        </div>
                        <!-- <div v-if="item.optionValue.length> 0">
                            <template v-if="item.isChecked && item.optionValue.find( obj => obj.optionValueDescription )">
                            <select class="form-select p-2 mt-2" style="width: 100%;" aria-label="Default select example" :value="getFirstOptionValue(index)"
                            @change="updateFirstOptionValue(index, $event.target.value)" @blur="selectFirstAddon(item, index)" :class="{'errorSelect': item.addonSelect.errorMessage && !item.addonSelect.value}">
                            <option value="" disabled>Select {{ item.optionDescription }}</option>
                            <option v-for="(option, index) in item.optionValue" :key="index" :value="option.optionValueDescription"  v-show="option.optionValueDescription">
        {{ option.optionValueDescription }}
      </option>
                            
                          </select>
                          <small v-if="item.addonSelect.errorMessage && !item.addonSelect.value" class="errorTextSelect">{{ item.addonSelect.errorMessage }}</small>
                         
                          <select class="form-select p-2 mt-2" style="width: 100%;" aria-label="Default select example" v-if="userSelectedAddons[index]?.firstOption.value" :value="getSecondOptionValue(index)" @change="updateSecondOptionValue(index, $event.target.value)" @blur="selectSecondAddon(item, index)"  :class="{'errorSelect': item.amountOfAddon.errorMessage && !item.amountOfAddon.value}">
                            <option value="" disabled>Select Amount</option>
                              <option>
                         {{  item.optionValue.find(obj => obj.optionValueDescription == userSelectedAddons[index].firstOption.value)?.optionValueInput }}
                              </option>
                            
                          </select>
                          <small v-if="item.amountOfAddon.errorMessage && !item.amountOfAddon.value" class="errorTextSelect">{{ item.amountOfAddon.errorMessage }}</small>
                          
                        </template>
                          
                             </div> -->

                        <div v-if="item.displayAllAddons.length> 0 && item.isChecked &&!item.checkBoxStatus">
                          <div v-for="(firstAddon, addonIndex) in item.optionValue" :key="addonIndex" :class="{'mb-2 bg-light rounded p-2': item.optionValue.length>1}" class="position-relative addon-selection">
                            <template v-if="item.isChecked">
                                <select class="form-select p-2 mt-0" style="width: 100%;"
                                  v-if="quoteCategory === 'Roll-Over' || (quoteCategory === 'New' && firstAddon.optionValueSelected !== 'existingCover')"
                                  v-model="firstAddon.optionValueSelected"
                                  aria-label="Default select example" >
                                  <option :value="firstAddon.optionValueSelected">{{ firstAddon.optionValueDescription }}</option>
                                </select>
                                <!-- <small v-if="firstAddon.addonSelect.errorMessage && !firstAddon.addonSelect.value" class="errorTextSelect">{{ firstAddon.addonSelect.errorMessage }}</small> -->
                                  <span v-if="quoteCategory === 'Roll-Over' || (quoteCategory === 'New' && firstAddon.optionValueSelected !== 'existingCover')">
                                <select class="form-select p-2 mt-2" style="width: 100%;"
                                  v-if="firstAddon.type != undefined && firstAddon.type.toLowerCase()=='single-select'"
                                  v-model="firstAddon.default"
                                  @change="updateFirstOptionValue(index, $event.target.value, addonIndex)"
                                  @blur="selectFirstAddon(item, index, addonIndex)"
                                  aria-label="Default select example" >
                                  <option value="" disabled >Select Amount</option>
                                  <option v-for="value in firstAddon.optionValueInput" :key="value.text" :value="value.value">{{ value.text }}</option>
                                </select>
                                <input v-if="firstAddon.type != undefined && firstAddon.type.toLowerCase()=='text'" type="text" v-model="firstAddon.default" @change="validateInput($event,item.optionValue[0].min,item.optionValue[0].max)" @input="updateSecondOptionValue(index, $event.target.value, addonIndex,1)"  style="width: 100%; height: 35px;" :placeholder="`Please enter value between ${firstAddon.min} and ${firstAddon.max}`" :min="firstAddon.min" :max="firstAddon.max" class="my-1 text-field-enter" @keypress="validateInputToString($event)">
                                <!-- <small v-if="(item.optionValue[0].type).toLowerCase()==='text'" class="errorTextSelect">{{ `Please enter value between ${item.optionValue[0].min} and ${item.optionValue[0].max}` }}</small> -->
                                <!-- <small v-if="firstAddon.amountOfAddon.errorMessage && !firstAddon.amountOfAddon.value" >{{ firstAddon.amountOfAddon.errorMessage }}</small> -->
                                 </span>
                            </template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <div class="tab-content  col-md-6" id="myTabContent">
            <div v-if="responseData.length == 0">
              <SingleQuoteLoader />
            </div>

            <div v-for="(step, index) in steps" :key="'content-' + index" :class="[
              'tab-pane',
              'fade',
              { show: activeTab === index, active: activeTab === index },
            ]" role="tabpanel" :id="'step' + (index + 1)" :aria-labelledby="'step' + (index + 1) + '-tab'" style="position: sticky;
    top: 7rem;">
              <div v-if="responseData.length">

                <div class="headingcards mt-3" key="Plan Summary">
                  <h3>Plan Summary</h3>
                  <img class="confirmimage" :key="responseData[0].supportingData.insCompanyLogo"
                    :src="resolveImagePath(responseData[0].supportingData.insCompanyLogo)" alt="Bank Logo" />
                  <div class="d-flex align-items-center mt-3 justify-content-between">
                    <p>Plan Type</p>
                    <h4>{{ responseData[0].insuranceAndProducts?.policyType }}</h4>
                  </div>
                  <div class="d-flex align-items-center justify-content-between" v-if="(responseData[0].insuranceAndProducts?.policyType)!=='StandaloneTP(1)'">
                    <p>IDV Cover</p>
                    <h4>&#8377;{{currency(responseData[0].supportingData.insuredDeclaredValue.suggestedIDV) }}</h4>
                    
                  </div>

                  <hr />
                  <div class="d-flex align-items-center mt-3 justify-content-between">
                    <p>Premium Amount</p>
                    <h4>&#8377;{{
                      currency(this.responseData[0].productDetails.totalPremiumDetails[0].totalPremiumValueBeforeTax) }}
                    </h4>
                  </div>
                  <div class="d-flex align-items-center justify-content-between">
                    <p>GST @18%</p>
                    <h4>&#8377;{{ currency(this.responseData[0].productDetails.totalPremiumDetails[0].totalTax) }}</h4>
                  </div>
                  <hr />
                  <div class="d-flex align-items-center justify-content-between">
                    <h4>Total Amount</h4>
                    <h6>&#8377;{{
                      currency(this.responseData[0].productDetails.totalPremiumDetails[0].totalPremiumWithTax) }}</h6>

                  </div>
                  <div class="text-end">
                    <span @click="showBreakupmodal()" class="text-primary show-breakup-link"
                      style="font-size: 12px;text-decoration: underline;">Show Breakup</span>
                  </div>
                </div>


                <div class="row ">
                  <div class="col-md-12">
                    <button class="backbutton sendquote mt-3" 
                     @click="showSendQuoteModal()">Send Quote</button>

                  </div>
                  <div class="col-md-6">
                    <router-link to="/policy-quote">
                      <button class="backbutton mt-3">Back</button>
                    </router-link>
                  </div>
                  <div class="col-md-6">

                    <button class="btn commonButton mb-2 mt-3" v-if="recalculate == true"
                      @click="proceedWithSingleQuote()">
                      Recalculate Premium</button>
                    <!-- <router-link to="/ckyc-form"> -->
                    <button class="btn commonButton mb-2 mt-3" v-if="recalculate == false" :disabled="loading2"
                      @click="goToProposal()">
                      <div class="loader2" v-if="loading2">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <span v-if="!loading2">Proceed to Proposal</span>
                    </button>
                    <!-- </router-link> -->
                  </div>
                </div>
              </div>
              <!-- <component :is="step.SelectComponent" /> -->
            </div>
            <div class="d-flex mt-3 justify-content-between" :class="{
              'justify-content-between':
                index !== 0 && index !== steps.length - 1,
              'justify-content-end': index === 0,
              'justify-content-between': index === steps.length - 1,
            }">

            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
  <sendQuoteModal v-if="this.sendQuoteModalFlag" @sendQuoteModalClose="sendQuoteModalClose()"/>
    <AmountBreakupSq v-if="this.openAmountBreakupflag" @emitClose="emitClose()" />
</template>

<script>
import { ref } from "vue";
import { getLazyLoadingConfig } from '@/mixins/common';
import SingleQuoteLoader from "../pageLoaders/singleQuoteLoader.vue";
import InnerHeader from "../header/innerHeader.vue";
import sendQuoteModal from "@/modals/sendQuoteModal.vue";
import AmountBreakupSq from "@/modals/amountBreakupSq.vue";
export default {

  components: {
    SingleQuoteLoader,
    InnerHeader,
    sendQuoteModal,
    AmountBreakupSq
  },
  methods: {
    validateInput(event,min,max){
      console.log("---e",event,min,max);  
      if(event.target.value<min ||event.target.value>max){
        event.target.value='';
      }
    },
    showBreakupmodal() {
      console.log("clicked-------");
      this.openAmountBreakupflag = true;
    },
    emitClose() {
      this.openAmountBreakupflag = false;
    },
    showSendQuoteModal() {
      this.sendQuoteModalFlag = true;
    },
    sendQuoteModalClose() {
      this.sendQuoteModalFlag = false;
     
    },
    goToProposal() {
      sessionStorage.setItem("addontoShow", JSON.stringify(this.selectedAddons));
      this.$router.push('/ckyc-form');
    },
    // Date fromatting to DD-MM-YYYY Fromat
    formatDate(date) {
      const dateObj = new Date(date);
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = dateObj.getFullYear();
      const formattedDate = `${day}-${month}-${year}`;
      return formattedDate;
    },
    //Convert Currency 
    currency(amt) {
      const tmpAmt = parseFloat(amt).toFixed(2);
      return tmpAmt.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    clearSelectedValues(objAddon, isChecked, selectedCode) {
      let self = this;
      if(objAddon.addonSelect != undefined){
        objAddon.addonSelect.stepMandatory = isChecked;
      }
      if (!isChecked) {
        objAddon.selectedAllAddons.forEach(element => {
          element.addonSelect.errorMessage = "";
          element.addonSelect.value = "";
          element.addonSelect.stepMandatory = false;
          element.amountOfAddon.errorMessage = "";
          element.amountOfAddon.value = "";
          element.amountOfAddon.stepMandatory = false;
        });
        let currentobj = self.userSelectedAddons.find(obj => obj.code == selectedCode);
        if (currentobj) {
          currentobj.firstOption.value = null;
          currentobj.secondOption.value = null;
        }
        if(objAddon.addonSelect != undefined){
          objAddon.addonSelect.value = "";
          objAddon.addonSelect.errorMessage = "";
          objAddon.addonSelect.stepMandatory = false;
        }
      }else{
        if(objAddon.addonSelect != undefined){
          objAddon.addonSelect.errorMessage = objAddon.addonSelect.errorText;
          objAddon.addonSelect.stepMandatory = true;
        }
      }
      setTimeout(() => {
        self.selectedAddons = self.filteredOptions.filter(addon => addon.isChecked);
      }, 100);
    },
    getSPDetailsOneSB(rm_code, man_code) {
      let reqData = [man_code];
      let headers = {};
      this.backendApi("getSPDetailsOneSB", reqData, headers, "get")
        .then((response) => {
          if (response == 'error') {
            self.apiError = true;
          } else if (response && response.data) {
            console.log('response sp details: ', response.data);
            if (response.data.status == "failed") {
              //redirection to error page and on go back button redirect to homepage
              let msg = ['SP details are not available']
              // this.$router.push({ path: '/networkalert', query: {backUrl:'multi-quote', msg: msg }});
            }
            this.spdata = response.data.data;
            //   this.quotationSQcall();
            sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.data));
            sessionStorage.setItem("sp_details_data", JSON.stringify(response.data.data));
            this.spdataFlagForSQ = true;
            this.apiReqData.distributor.agentID = this.spdata.spCode;
            for (var attr in this.spdata.attributes) {
              try {
                let varfield = {};
                varfield["fieldName"] = attr;
                varfield["fieldValue"] = this.spdata.attributes[attr];
                this.apiReqData.distributor.varFields.push(varfield);
              } catch (err) {
                console.log("varfield assignment failed", err);
              }
            }
          }
        })
        .catch((error) => {
          console.log('error msg block spdetailOneSB API', error);
          let msg = ['SP details are not available']
          // this.$router.push({ path: '/networkalert', query: {backUrl:'multi-quote', msg: msg }});
        });
    },
    // updateAddOnsValue(term) {
    //   this.filteredOptions.forEach((obj, index) => {
    //     if(obj.isChecked && obj.selectedAllAddons?.length > 0){
    //       obj.selectedAllAddons.some((addon, index) => {
    //         if(!addon.addonSelect.value){
    //           addon.addonSelect.errorMessage = addon.addonSelect.errorText
    //         }
    //       })
    //       obj.selectedAllAddons.some((addon, index) => {
    //         if(obj.isChecked && addon.addonSelect?.value && addon?.amountOfAddon != undefined){
    //           if(!addon.amountOfAddon.value){
    //             addon.amountOfAddon.errorMessage = addon.amountOfAddon.errorText
    //           }
    //         }
    //       })
    //     }
    //   });

    //   const noErrors = this.checkErrorMessages(this.filteredOptions);

    //   console.log(noErrors);
    //   if (!noErrors) {
    //     return;
    //   }else{
    //     if(term == 'recalculate'){
    //       for (var i = 0; i < this.selected_plan_data.productDetails.motorCovers.length; i++) {
    //         let tempArr = {
    //           "optionSelected": this.selected_plan_data.productDetails.motorCovers[i]["optionSelected"],
    //           "optionValue": this.selected_plan_data.productDetails.motorCovers[i]["optionValue"],
    //           "varFields": [
    //             {
    //               "fieldName": "",
    //               "fieldValue": ""
    //             }
    //           ]
    //         };
    //         console.log("moter", this.responseData);
    //         // let itemIndex = this.responseData.productDetails.motorCovers.findIndex(item);
    //         this.apiReqData.product.motorCovers[i] = tempArr;
    //       }
    //       this.proceedWithSingleQuote();
    //     }else if(term == 'proceed'){
    //       this.goToProposal()
    //     }
    //   }

    // },

    checkErrorMessages(data) {
    for (const item of data) {
        // Check error messages in the selectedAllAddons array
        if (item.selectedAllAddons && Array.isArray(item.selectedAllAddons)) {
            for (const addon of item.selectedAllAddons) {
                if (addon.addonSelect && addon.addonSelect.errorMessage && addon.addonSelect.errorMessage.trim() !== "") {
                    return false;
                }
                if (addon.amountOfAddon && addon.amountOfAddon.errorMessage && addon.amountOfAddon.errorMessage.trim() !== "") {
                    return false;
                }
            }
        }
    }
    return true;
}
,
    proceedWithSingleQuote(){
      this.recalculate = false;
      this.apiReqData.product.motorCovers = [];
      for (var i = 0; i < this.selectedAddons.length; i++) {
        var optionvalueArray=[];
        const current_addOn=this.selectedAddons[i].optionSelected;
        this.selectedAddons[i].optionValue.map((option)=>{
          const obj={
                optionValueDescription:option.optionValueDescription,
                optionValueInput:option.default,
                optionValueSelected:option.optionValueSelected
               }
         optionvalueArray.push(obj);

        })
        console.log("current_addOn-----------------",current_addOn)
        const addon_from_filteredData=this.filteredOptions.filter((option)=>{
               if(option.optionSelected==current_addOn){
                return option;
               }
        }
      );
      console.log("addonarrayfrom filterdata",addon_from_filteredData[0]);
      addon_from_filteredData[0].selectedAllAddons.map((item)=>{
               const addonselectvalue= item.addonSelect.value;
               const addon_amount=item.amountOfAddon.value;
              optionvalueArray.forEach(item => {
    if (item.optionValueDescription === addonselectvalue) {
        item.optionValueInput = addon_amount; // Assigning addon_amount to optionValueInput
    }
});
              //  optionvalueArray.push(obj);          
      })
      console.log("optionarray",optionvalueArray);
        let tempArr = {
          "optionSelected": this.selectedAddons[i]["optionSelected"],
          // "optionValue": this.selectedAddons[i]["optionValue"],
          "optionValue":optionvalueArray,
          "varFields": [
            {
              "fieldName": "",
              "fieldValue": ""
            }
          ]
        };
        // console.log("moter", this.responseData);
        // let itemIndex = this.responseData.productDetails.motorCovers.findIndex(item);
        this.apiReqData.product.motorCovers[i] = tempArr;
      }
      console.log("new api request,", this.apiReqData);
      this.apiReqData.distributor.agentID = "FSC0123786";
      this.getSingleQuote();
      this.apiReqData.product.motorCovers = [];
    },
    //get quote
    getSingleQuote() {
      var self = this;
      self.loading2 = true;
      console.log("this.requestBody", this.apiReqData)
      sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
      this.backendApi("quotationApi", this.apiReqData, { "Authorization": `Bearer ${this.$store.state.token}` }, "post")
        .then((response) => {
          console.log("inside response sq")
          if (response.data.errors.length > 0) {
            this.loading = false;
            const msg = [];
            for (let i = 0; i < response.data.errors.length; i++) {
              msg.push(response.data.errors[i]["errorMessage"])
            }
            console.log("Errors in Response", msg)
            this.$router.push({ path: '/error-page', query: { backUrl: 'policy-quote', msg: msg } });
          } else if (response && response.data) {
            
            this.requestId = response.data.reqId;
            this.loading = false;
            if (response?.data?.data?.quote?.length > 0) {
              console.log("inside loop for")
              this.responseData.push(response.data.data.quote)
            }
            if (response.data.errors.length == 0) {
              setTimeout(function () {
                self.pollapiflag = true;
                self.pollData();
              }, 1000);
            }
            else {
              console.log("inside else case")
              this.loading2 = false;
              if (this.responseData.length == 0) {
                this.erMsg = 'No products found as per your selected filter. Please change the filters.';
                self.beforeDestroy();
              }
            }
          }

        }).catch((error) => {
          console.log('error msg block dp', error);
          const msg = "Service is Unavailable, Please try again later.";
          this.$router.push({ path: '/error-page', query: { backUrl: 'policy-quote', msg: msg } });
        })
    },
    getFirstOptionValue(index) {
      return this.userSelectedAddons[index]?.firstOption?.value || ''
    },
    getSecondOptionValue(index) {
      return this.userSelectedAddons[index]?.secondOption?.value || ''
    },
    isOptionDisabled(addons, optionValueDescription, currentIndex) {
      return addons.some( (addon, index) => {
        return addon.addonSelect.value == optionValueDescription
      })
    },
    updateSecondOptionValue(index, value,addon,tostring){
     
      this.recalculate = true;
      this.userSelectedAddons[index].secondOption.value = value;
      this.filteredOptions[index].amountOfAddon.value = value;
        
    },
    validateInputToString(event){
      event.target.value = event.target.value.replace(/[^0-9]/g, '');
    },
    updateFirstOptionValue(index, value, addonIndex) {
      this.userSelectedAddons[index].firstOption.value = value;
      this.recalculate = true;
      // this.filteredOptions[index].addonSelect.value = value;
      // this.filteredOptions[index].selectedAllAddons[addonIndex].amountOfAddon.value = "";
      // this.filteredOptions[index].selectedAllAddons[addonIndex].amountOfAddon.errorMessage = "";
    },
    // Handle Error text in first Select
    selectFirstAddon(item, index, addonIndex){
      // item.selectedAllAddons[addonIndex].addonSelect.errorMessage = item.selectedAllAddons[addonIndex].addonSelect.errorText;
      // if(item.selectedAllAddons[addonIndex].addonSelect.value != ''){
      //   item.selectedAllAddons[addonIndex].addonSelect.errorMessage = "";
      // }
    },
    sleep(ms){
      return new Promise(resolve  => setTimeout(resolve, ms))
    },
    selectSecondAddon(item, index, addonIndex){
      if(item.selectedAllAddons[addonIndex] != undefined){
        item.selectedAllAddons[addonIndex].amountOfAddon.errorMessage = item.selectedAllAddons[addonIndex].amountOfAddon.errorText;
      }
      if(item.selectedAllAddons[addonIndex] != undefined && item.selectedAllAddons[addonIndex].amountOfAddon.value != ''){
        item.selectedAllAddons[addonIndex].amountOfAddon.errorMessage = "";
      }
    },
    //Polling API
    async getMotorProduct() {
      this.loading2 = true;
      const startTime = Date.now();
      const maxPollingTime = 3 * 60 * 1000;
      let headers = { "Authorization": `Bearer ${this.$store.state.token}` };
      let reqData = [this.requestId];

      while (this.pollapiflag && (Date.now() - startTime) < maxPollingTime) {
        try {
          if(this.$route.path !== "/policy-details"){
            this.pollapiflag = false;
            break;
          }
          const response = await this.backendApi("quotationPoll", reqData, headers, "get");
          if (response.data.errors.length > 0) {
            this.loading = false;
            const msg = [];
            for (let i = 0; i < response.data.errors.length; i++) {
              msg.push(response.data.errors[i]["errorMessage"])
            }
            this.$router.push({ path: '/error-page', query: { backUrl: 'policy-quote', msg: msg } });
          }
          if (response?.data?.data?.quote?.length > 0) {
            this.loading2 = false;
            this.suggestedIDV = response?.data?.data?.quote[0].supportingData.insuredDeclaredValue.suggestedIDV;
            this.responseData = response.data.data.quote;
            sessionStorage.setItem("quoteSingleData", JSON.stringify(this.responseData))
          }
          if (response.data.data.isPollComplete == true) {
            this.loading2 = false;
            this.pollapiflag = true;
            this.beforeDestroy();
            break;
          }
          if (response.data.errors.length > 0) {
            if (response.data.errors[0].errorIdentifier == "INVALID_REQUEST_ID") {
              this.pollapiflag = true;
            }
          }
          await this.sleep(1000);
        }catch(err){
          console.log('error msg block', err);
          this.loading = false;
        }
      };
      if ((Date.now() - startTime) >= maxPollingTime) {
        this.pollapiflag = false;
        this.loading2 = false;
        this.loading = false;
      }
    },
    //Polling Single quote
    //Get Polling Data
    pollData() {
        if (this.pollapiflag == true) {
          this.getMotorProduct();
        } else {
          this.loading2 = false;
          if (this.responseData?.length == 0) {
            this.erMsg = 'No products found as per your selected filter. Please change the filters.';
          }
          this.beforeDestroy();
        }
    },
    //Cleare Interval
    beforeDestroy() {
      this.disableButtons = false;
    },
    setupData() {
      console.log("Test");
      this.apiReqData.typeOfQuote = "Single Quote";
      this.apiReqData.product.policyTerm = 1;
      this.apiReqData.product.insuranceAndProducts[0].insuranceCompanyCode = this.selected_plan_data.insuranceAndProducts.insuranceCompanyCode;
      this.apiReqData.product.insuranceAndProducts[0].policyType = this.selected_plan_data.insuranceAndProducts.policyType;
      this.apiReqData.product.planOption.planId = this.selected_plan_data.productDetails.planOption.planId
      this.apiReqData.product.insuranceAndProducts[0].productCode[0] = this.selected_plan_data.insuranceAndProducts.productCode
      this.apiReqData.product.previousPolicyDetails.previousCoverageType = this.selected_plan_data.productDetails.previousPolicyDetails.previousCoverageType;
      this.apiReqData.product.idvDetails.insuredDeclaredValue = this.selected_plan_data.supportingData.insuredDeclaredValue.suggestedIDV
      this.apiReqData.journey_id = JSON.parse(sessionStorage.getItem("journey_id"));
      // this.apiReqData.product.motorCovers=this.selected_plan_data.productDetails.motorCovers;
      // this.apiReqData.product.motorCovers=[];
      this.apiReqData.product.policyTerm = parseInt(this.selected_plan_data.productDetails.policyTerm);
      this.apiReqData.product.premiumPaymentFrequency = this.selected_plan_data.productDetails.premiumPaymentFrequency;
      for (var i = 0; i < this.selected_plan_data.productDetails.motorCovers.length; i++) {
        // console.log("this.selectedAddons[i].optionValue",this.selectedAddons[i]["optionValue"])
        let tempArr = {
          "optionSelected": this.selected_plan_data.productDetails.motorCovers[i]["optionSelected"],
          "optionValue": this.selected_plan_data.productDetails.motorCovers[i]["optionValue"],
          "varFields": [
            {
              "fieldName": "",
              "fieldValue": ""
            }
          ]
        };
        console.log("moter", this.responseData);
        // let itemIndex = this.responseData.productDetails.motorCovers.findIndex(item);
        this.apiReqData.product.motorCovers[i] = tempArr;
      }
      this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
      this.apiReqData.distributor.channelType = this.rmData.RoleId !== "1" ? "B2B" : "B2C";
      sessionStorage.setItem("quote_request", JSON.stringify(this.apiReqData));
      this.getSingleQuote();
    },
    completed(index) {
      this.activeTab = index;
    },
    isCompleted(index) {
      return index < this.activeTab;
    },
    resolveImagePath(variable) {
      return variable || "";
    },

  },
  computed: {
    computedStyle(index) {
      if (index == this.filteredOptions.length - 1) {
        return {
          borderBottom: "none"
        }
      }
    }
  },
  mounted() {
    this.quoteCategory= this.$store.state.quoteCategory;
    if (sessionStorage.getItem("userData")) {
      this.userData = JSON.parse(sessionStorage.getItem("userData"));
      console.log(this.userData, "userData")
    }
    this.apiCallinginterval = parseInt(getLazyLoadingConfig()['inerval']);
    this.selected_plan_data = JSON.parse(sessionStorage.getItem("selected_plan_data"));
    let spdetail_flag = process.env.VUE_APP_SPDETAIL_ONESB;
    if (spdetail_flag == 1) {
      if (sessionStorage.getItem("rm_data")) {
        this.rmData = JSON.parse(sessionStorage.getItem("rm_data"));
        this.getSPDetailsOneSB(this.rmData.Userid, this.selected_plan_data.insuranceAndProducts.insuranceCompanyCode);
      }
    }
    this.apiReqData = JSON.parse(sessionStorage.getItem("quote_request"));
    this.apiReqData.product.idvDetails.insuredDeclaredValue = this.selected_plan_data.supportingData.insuredDeclaredValue.suggestedIDV;
    // this.selectedAddons=this.selected_plan_data.productDetails.motorCovers;
    this.filteredOptions = this.selected_plan_data.productDetails.motorCovers.filter(option => {
      // Check if all four conditions are met
      const isAllConditionsMet = option.inbuiltInPlan === "No" &&
        option.mandatoryInProduct === "No" &&
        option.premiumForEachPolicyTerm.every(term =>
          term.premiumForEachPPO.every(ppo =>
            ppo.premiumForEachPPF.every(ppf =>
              ppf.totalPremiumValue === 0 || ppf.totalPremiumValue === null
            )
          )
        ) &&
        option.premiumIncludedFlag === "No";

    // Return true if not all conditions are met (i.e., we want to keep this option)
    return !isAllConditionsMet;
}).map(elementObj => {
  let checkboxStatus = true;
  let isChecked = true;
  // console.log("---:", elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
  // premiumValue, elementObj.premiumIncludedFlag, elementObj.mandatoryInProduct, elementObj.inbuiltInPlan, "---:")
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue
>0 && elementObj.premiumIncludedFlag=='No' && elementObj.mandatoryInProduct=='No' && elementObj.inbuiltInPlan=='No'){
    checkboxStatus = false;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue>0 && elementObj.premiumIncludedFlag=='No' && elementObj.mandatoryInProduct=='No' && elementObj.inbuiltInPlan=='Yes'){
    checkboxStatus = true;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue>0 && elementObj.premiumIncludedFlag=='No' && elementObj.mandatoryInProduct=='Yes' && elementObj.inbuiltInPlan=='No'){
    checkboxStatus = true;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue>0 && elementObj.premiumIncludedFlag=='No' && elementObj.mandatoryInProduct=='Yes' && elementObj.inbuiltInPlan=='Yes'){
    checkboxStatus = true;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue>0 && elementObj.premiumIncludedFlag=='Yes' && elementObj.mandatoryInProduct=='No' && elementObj.inbuiltInPlan=='No'){
    checkboxStatus = false;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue>0 && elementObj.premiumIncludedFlag=='Yes' && elementObj.mandatoryInProduct=='No' && elementObj.inbuiltInPlan=='Yes'){
    checkboxStatus = true;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue>0 && elementObj.premiumIncludedFlag=='Yes' && elementObj.mandatoryInProduct=='Yes' && elementObj.inbuiltInPlan=='No'){
    checkboxStatus = true;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue>0 && elementObj.premiumIncludedFlag=='Yes' && elementObj.mandatoryInProduct=='Yes' && elementObj.inbuiltInPlan=='Yes'){
    checkboxStatus = true;
    isChecked = true;
  }
// 
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue==0 && elementObj.premiumIncludedFlag=='No' && elementObj.mandatoryInProduct=='No' && elementObj.inbuiltInPlan=='Yes'){
  console.log("I am here eeeeee----------------------")
    checkboxStatus = true;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue==0 && elementObj.premiumIncludedFlag=='No' && elementObj.mandatoryInProduct=='Yes' && elementObj.inbuiltInPlan=='No'){
    checkboxStatus = true;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue==0 && elementObj.premiumIncludedFlag=='No' && elementObj.mandatoryInProduct=='Yes' && elementObj.inbuiltInPlan=='Yes'){
    checkboxStatus = true;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue==0 && elementObj.premiumIncludedFlag=='Yes' && elementObj.mandatoryInProduct=='No' && elementObj.inbuiltInPlan=='No'){
    checkboxStatus = false;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue==0 && elementObj.premiumIncludedFlag=='Yes' && elementObj.mandatoryInProduct=='No' && elementObj.inbuiltInPlan=='Yes'){
    checkboxStatus = true;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue==0 && elementObj.premiumIncludedFlag=='Yes' && elementObj.mandatoryInProduct=='Yes' && elementObj.inbuiltInPlan=='No'){
    checkboxStatus = true;
    isChecked = true;
  }
  if(elementObj.premiumForEachPolicyTerm[0].premiumForEachPPO[0].premiumForEachPPF[0].
premiumValue==0 && elementObj.premiumIncludedFlag=='Yes' && elementObj.mandatoryInProduct=='Yes' && elementObj.inbuiltInPlan=='Yes'){
    checkboxStatus = true;
    isChecked = true;
  }
  return {
      ...elementObj,
      checkBoxStatus: checkboxStatus,
      isChecked: isChecked,
      displayAllAddons: [],
      selectedAllAddons: []
    };
  });
  this.filteredOptions.forEach(addon => {
      if (addon.optionValue && addon.optionValue.length > 0) {
        addon.addonSelect = {
          value: "",
          stepMandatory: false,
          errorText: addon.optionDescription + " is Required",
          errorMessage: ""
      };
    }
  });
  this.filteredOptions.forEach(addon => {
      if (addon.optionValue && addon.optionValue.length > 0) {
        addon.amountOfAddon = {
          value: "",
          stepMandatory: false,
          errorText: "Amount is Required",
          errorMessage: ""
      };
    }
  });

  this.filteredOptions.forEach(addon => {
  if (addon.optionValue && addon.optionValue.length > 0) {
    addon.optionValue.forEach((element, index) => {
      const newAddon = {
        addonSelect: {
          value: "",
          stepMandatory: false,
          errorText: element.optionValueDescription + " is Required",
          errorMessage: ""
        },
        amountOfAddon: {
          value: "",
          stepMandatory: false,
          errorText: "Amount is Required",
          errorMessage: ""
        }
      };
      addon.displayAllAddons.push(newAddon);
    });
  }
});
  this.filteredOptions.forEach(addon => {
  if (addon.optionValue && addon.optionValue.length > 0) {
    const newAddon = {
      addonSelect: {
        value: "",
        stepMandatory: false,
        errorText: "Addon Selection is Required",
        errorMessage: ""
      },
      amountOfAddon: {
        value: "",
        stepMandatory: false,
        errorText: "Amount is Required",
        errorMessage: ""
      }
    };
    addon.selectedAllAddons.push(newAddon);
  }
});



    console.log("this.filteredOptions ", this.filteredOptions);
    this.selectedAddons = this.filteredOptions;


    this.userSelectedAddons = this.selectedAddons.map(addon => {
      const firstOption = addon.optionValue?.length > 0 ? addon.optionValue[0] : null;
      const secondOption = firstOption && firstOption.optionValueInput?.length > 0 ? firstOption.optionValueInput : "";
      return {
        code: addon.optionSelected,
        name: addon.optionDescription,
        firstOption: firstOption ? { value: "" } : { value: null },
        secondOption: secondOption ? { value: "" } : { value: null }
      }
    })
    this.setupData();
    
const key=(this.selected_plan_data.productDetails.planOption.planId).toString();
this.ADDONS_MASTER=this.$store.state.configData.ADDONS_MASTER;
if(this.ADDONS_MASTER!=='' && this.ADDONS_MASTER!==undefined){
  console.log("------------------",this.ADDONS_MASTER)
const addon_withkey=this.ADDONS_MASTER[key];
console.log(addon_withkey,"oneaddond----------")
for(var i=0;i<this.filteredOptions.length;i++){
  const optionselected=this.filteredOptions[i].optionSelected;
  console.log("optionselected from filterdata",optionselected);
  if(addon_withkey!==undefined){
  const filtered_list=addon_withkey.filter((option)=>{
         return option.optionSelected===optionselected}

);
// console.log("option list from addon master",filtered_list);

for(var j=0;j<this.filteredOptions[i].optionValue.length;j++){
  const ans=filtered_list.filter((option=>{
    console.log("option",option)
    return option.optionValueSelected===this.filteredOptions[i].optionValue[j].optionValueSelected;    
  }))
  console.log("ans1",ans);
  const valuesArray = ans.map(option => option.values)[0];
  if(valuesArray.length>0){
  this.filteredOptions[i].optionValue[j].optionValueInput=valuesArray;
  this.filteredOptions[i].optionValue[j].type=ans[0].type;
  this.filteredOptions[i].optionValue[j].min=ans[0].min;
    this.filteredOptions[i].optionValue[j].max=ans[0].max;
    this.filteredOptions[i].optionValue[j].default=ans[0].default;
}
  else{
    const optionarray=[];
    const obj={};
    obj.text=ans[0].default;
    obj.value=ans[0].default;
    optionarray.push(obj);
    console.log("obj----------",obj);
    this.filteredOptions[i].optionValue[j].optionValueInput=optionarray;
    this.filteredOptions[i].optionValue[j].type=ans[0].type;
    this.filteredOptions[i].optionValue[j].min=ans[0].min;
    this.filteredOptions[i].optionValue[j].max=ans[0].max;
    this.filteredOptions[i].optionValue[j].default=ans[0].default;
  }
  console.log("ans",this.filteredOptions)
}}
// console.log("filtered_list",filtered_list)


  console.log("--option",optionselected)
}}
  },
  data() {
    return {
      quoteCategory:'',
      sendQuoteModalFlag:false,
      recalculate: false,
      userData: {},
      responseData: [],
      apiCallinginterval: '',
      rmData: null,
      selected_plan_data: null,
      quote_request: null,
      selectedAddons: [],
      userSelectedAddons: [],
      filteredOptions: [],
      loading2: false,
      openAmountBreakupflag: false,
      hasFormFieldError: false,
      ADDONS_MASTER:[],
      items: [
        {
          heading: "Plan Summary",
          BankLogo: "$SBI",
          planType: "Plan Type",
          plan: "₹ 14,560",
          coverType: "IDV Cover",
          cover: "₹13,36,452",
          nestedItems: [
            {
              checkitems: "Zero Paper Claims",
            },
            {
              checkitems: "Spot Claims Upto Rs. 20k",
              carcover: "₹ 14,560",
            },
            {
              checkitems: "28 Cashless Garages",
            },
            {
              checkitems: "Self-Video Claims",
            },
          ],
          premiumAmount: "Premium Amount",
          amount: "₹ 14,560",
          GSTType: "GST @18%",
          GST: "₹ 2620",
          amountTitle: "Total Amount",
          totalAmount: "₹ 17,180",
        },
      ],
      Addon: [
        {
          checkheadings: "Zero Paper Claims",
        },
        {
          checkheadings: "Spot Claims Upto Rs. 20k",
        },
        {
          checkheadings: "28 Cashless Garages",
        },
        {
          checkheadings: "Self-Video Claims",
        },
      ],
    };
  },
  setup() {
    const activeTab = ref(0);
    const steps = ref([
      {
        title: "Step 1",
        icon: "2",
        subcontent: "Choose  <b>City</b>and  <b>RTO</b>",
        SelectComponent: "SingleQuote",
      },
      {
        title: "Step 1",
        icon: "2",
        subcontent: " Choose <b>Car Make</b>",
        SelectComponent: "SingleQuote",
      },

      {
        title: "Step 1",
        icon: "2",
        subcontent: "Choose  <b>Car Model</b> ",
        SelectComponent: "SingleQuote",
      },
      {
        title: "Step 1",
        icon: "2",
        subcontent: "Choose  <b>Car Fuel Type</b> ",
        SelectComponent: "SingleQuote",
      },

      {
        title: "Step 1",
        icon: "2",
        subcontent: " Choose  <b>Car Varient</b> ",
        SelectComponent: "SingleQuote",
      },
      {
        title: "Step 1",
        icon: "2",
        subcontent: "<b>Additional</b> Details ",
        SelectComponent: "SingleQuote",
      },
      {
        title: "Step 1",
        icon: "2",
        subcontent: "<b>Claim</b>  ",
        SelectComponent: "SingleQuote",
      },

    ]);

    const nextStep = () => {
      if (activeTab.value < steps.value.length - 1) {
        activeTab.value += 1;
      }
    };

    const previousStep = () => {
      if (activeTab.value > 0) {
        activeTab.value -= 1;
      }
    };

    const selectTab = (index) => {
      activeTab.value = index;
    };

    return {
      activeTab,
      steps,
      nextStep,
      previousStep,
      selectTab,
    };

  },

};
</script>

<style scoped lang="scss">
@import "../../assets/scss/components/select-card.scss";
@import "../../assets/scss/components/multi-quotes.scss";
@import "../../assets/scss/components/form-wizard.scss";

.show-breakup-link {
  font-size: 12px;
  // text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s ease;
}
.dark .show-breakup-link{
  color: $main-bg !important;
}
.disableCheck{
  opacity: 0.6;
  cursor: not-allowed !important;
}
.add-cover{
  background-color: #d4e5ff;
  color: #222;
  border-radius: 100px;
  padding: 4px 8px;
  font-size: 12px;
  &:disabled{
    background-color: #eaeaea;
    border: none;
    color: #222;
    border-radius: 100px;
    padding: 4px 8px;
    opacity: 0.6;
    cursor: not-allowed !important;
   }
}
.floating-action{
  // box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.3);
  &>span{
    font-size: 14px !important;
  }
  &>button>svg{
    stroke: #ff6c6c !important;
    fill: #ff6c6c !important;
    width: 16px;
  }
  padding: 2px;
  position: absolute;
  left: -7%;
  top: 50%;
  transform: translate(50%, -50%);
  z-index: 2;
  border-radius: 6px;
}
</style>
